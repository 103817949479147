<template>
  <div>
    <section class="tables">
      <div class="row">
        <Breadcrumb :breadcrumbs="breadcrumbs" />
        <div class="col-lg-10 offset-lg-1 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Edit bus</h4>
              <b-form @submit.prevent="updateBus">
                <b-form-group
                  label="Name"
                  label-for="name-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    id="name-input"
                    v-model.trim="$v.form.name.$model"
                    type="text"
                    placeholder="Enter name"
                    :class="{
                      'is-invalid': submitted || $v.form.name.$error,
                    }"
                    :state="validateState('name')"
                    @blur="$v.form.name.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.form.name.required">
                    name is required
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="!$v.form.name.alphaNumSpace"
                    id="input-1-live-feedback"
                    >Only alphanumerics characters with space are
                    allowed</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.form.name.uniqueName">
                    Bus name is already registered.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label="Plate/Registration Number"
                  label-for="reg-no-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    id="reg-no-input"
                    v-model.trim="$v.form.reg_no.$model"
                    type="text"
                    placeholder="Enter plate/registration number"
                    :class="{
                      'is-invalid': submitted || $v.form.reg_no.$error,
                    }"
                    :state="validateState('reg_no')"
                    @blur="$v.form.reg_no.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.reg_no.required"
                    class="invalid-feedback"
                  >
                    plate/registration number is required
                  </b-form-invalid-feedback>

                  <b-form-invalid-feedback v-if="!$v.form.reg_no.minLength">
                    Plate/registration number must hav at min
                    {{ $v.form.reg_no.$params.minLength.min }} letters.
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.form.reg_no.maxLength">
                    Plate/registration number must have at max
                    {{ $v.form.reg_no.$params.maxLength.max }} letters.
                  </b-form-invalid-feedback>

                  <b-form-invalid-feedback
                    v-if="!$v.form.name.alphaNumSpace"
                    id="input-1-live-feedback"
                    >Only alphanumerics characters with space are
                    allowed</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.form.reg_no.uniqueRegNo">
                    This plate/registration number is already registered.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Model No."
                  label-for="model-no-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    id="model-no-input"
                    v-model.trim="$v.form.model_no.$model"
                    type="text"
                    placeholder="Enter model no."
                    :class="{
                      'is-invalid': submitted || $v.form.model_no.$error,
                    }"
                    :state="validateState('model_no')"
                    @blur="$v.form.model_no.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.model_no.required"
                  >
                    model no is required
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="!$v.form.model_no.alphaNum"
                    id="input-1-live-feedback"
                    >Only alphanumerics characters are
                    allowed</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.form.reg_no.uniqueModelNo">
                    This Model number is already registered.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Chassis No."
                  label-for="chassis-no-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    id="chassis-no-input"
                    v-model.trim="$v.form.chassis_no.$model"
                    type="text"
                    placeholder="Enter chassis no."
                    :class="{
                      'is-invalid': submitted || $v.form.chassis_no.$error,
                    }"
                    :state="validateState('chassis_no')"
                    @blur="$v.form.chassis_no.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.chassis_no.required"
                  >
                    chassis no is required
                  </b-form-invalid-feedback>

                  <b-form-invalid-feedback
                    v-if="!$v.form.chassis_no.alphaNum"
                    id="input-1-live-feedback"
                    >Only alphanumerics characters are
                    allowed</b-form-invalid-feedback
                  >

                  <b-form-invalid-feedback
                    v-if="$v.form.reg_no.uniqueChassisNo"
                  >
                    This chassis number is already registered.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Brand No"
                  label-for="brand-no-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-input
                    id="chassis-no-input"
                    v-model.trim="$v.form.brand.$model"
                    type="text"
                    placeholder="Enter brand name."
                    :class="{
                      'is-invalid': submitted || $v.form.brand.$error,
                    }"
                    :state="validateState('brand')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.brand.required"
                    class="invalid-feedback"
                  >
                    brand no is required
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="!$v.form.brand.alphaNum"
                    id="input-1-live-feedback"
                    >Only alphanumerics characters are
                    allowed</b-form-invalid-feedback
                  >
                </b-form-group>

                <b-form-group
                  label="Amenities"
                  label-for="tags-pills"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-select
                    :options="amenitieslists"
                    v-model.trim="$v.form.amenities.$model"
                    :class="{
                      'is-invalid': submitted || $v.form.amenities.$error,
                    }"
                    :state="validateState('amenities')"
                    multiple
                  ></b-form-select>
                  <b-form-invalid-feedback
                    >Amenities is a required field.</b-form-invalid-feedback
                  >
                </b-form-group>

                <b-form-group
                  label="Bus Type"
                  label-for="bus-type-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-select
                    v-model.trim="$v.form.bustypeId.$model"
                    :options="bustypes"
                    :class="{
                      'is-invalid': submitted || $v.form.bustypeId.$error,
                    }"
                    :state="validateState('bustypeId')"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Please select an bus type --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.bustypeId.required"
                  >
                    bus type is required
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Bus Layout"
                  label-for="bus-layout-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-select
                    v-model.trim="$v.form.buslayoutId.$model"
                    :options="buslayouts"
                    :class="{
                      'is-invalid': submitted || $v.form.buslayoutId.$error,
                    }"
                    :state="validateState('buslayoutId')"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Please select an bus layout --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.buslayoutId.required"
                    class="invalid-feedback"
                  >
                    bus layout is required
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Status "
                  label-for="status-input"
                  class="mt-3"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <b-form-radio-group
                    :options="options"
                    name="status"
                    v-model.trim="$v.form.status.$model"
                    :class="{
                      'is-invalid': submitted || $v.form.status.$error,
                    }"
                    :state="validateState('status')"
                  ></b-form-radio-group>

                  <b-form-invalid-feedback
                    v-if="submitted || !$v.form.status.required"
                    >Please select one</b-form-invalid-feedback
                  >
                </b-form-group>

                <p class="card-description fw-500"><b>Documents</b></p>
                <br />
                <b-form-group
                  label="Bus picture"
                  label-for="picture-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <div v-if="!form.picture">
                    <b-form-file
                      id="picture-input"
                      accept="image/jpeg, image/png, image/jpg"
                      placeholder="Choose a Profile picture or drop it here..."
                      @change="onFileChange($event, 'picture')"
                    ></b-form-file>
                  </div>
                  <div v-else>
                    <img
                      class="img-fluid"
                      :src="form.picture"
                      width="150"
                      height="150"
                    />
                    <button
                      class="btn social-btn btn-rounded btn-danger mr-4"
                      @click="removeImage('picture')"
                    >
                      <i class="mdi mdi-close"></i>
                    </button>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Certificate registration"
                  label-for="certificate-registration-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <div v-if="!form.certificate_registration">
                    <b-form-file
                      id="certificate-registration-input"
                      accept="image/jpeg, image/png, image/jpg"
                      placeholder="Choose a Licence or drop it here..."
                      @change="onFileChange($event, 'certificate_registration')"
                    ></b-form-file>
                  </div>
                  <div v-else>
                    <img
                      class="img-fluid"
                      :src="form.certificate_registration"
                      width="350"
                      height="250"
                    />
                    <button
                      class="btn social-btn btn-rounded btn-danger mr-4"
                      @click="removeImage('certificate_registration')"
                    >
                      <i class="mdi mdi-close"></i>
                    </button>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Certificate Pollution"
                  label-for="certificate-pollution-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <div v-if="!form.certificate_pollution">
                    <b-form-file
                      id="certificate-pollution-input"
                      accept="image/jpeg, image/png, image/gif"
                      placeholder="Choose a certificate pollution or drop it here..."
                      @change="onFileChange($event, 'certificate_pollution')"
                    ></b-form-file>
                  </div>
                  <div v-else>
                    <img
                      class="img-fluid"
                      :src="form.certificate_pollution"
                      width="350"
                      height="250"
                    />
                    <button
                      class="btn social-btn btn-rounded btn-danger mr-4"
                      @click="removeImage('certificate_pollution')"
                    >
                      <i class="mdi mdi-close"></i>
                    </button>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Certificate Insurance"
                  label-for="certificate-insurance-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <div v-if="!form.certificate_insurance">
                    <b-form-file
                      id="certificate-insurance-input"
                      accept="image/jpeg, image/png, image/gif"
                      placeholder="Choose a certificate insurance or drop it here..."
                      @change="onFileChange($event, 'certificate_insurance')"
                    ></b-form-file>
                  </div>
                  <div v-else>
                    <img
                      class="img-fluid"
                      :src="form.certificate_insurance"
                      width="350"
                      height="250"
                    />
                    <button
                      class="btn social-btn btn-rounded btn-danger mr-4"
                      @click="removeImage('certificate_insurance')"
                    >
                      <i class="mdi mdi-close"></i>
                    </button>
                  </div>
                </b-form-group>

                <b-form-group
                  label="Certificate Fitness"
                  label-for="certificate-fitness-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <div v-if="!form.certificate_fitness">
                    <b-form-file
                      id="certificate-fitness-input"
                      accept="image/jpeg, image/png, image/gif"
                      placeholder="Choose a certificate fitness or drop it here..."
                      @change="onFileChange($event, 'certificate_fitness')"
                    ></b-form-file>
                  </div>
                  <div v-else>
                    <img
                      class="img-fluid"
                      :src="form.certificate_fitness"
                      width="350"
                      height="250"
                    />
                    <button
                      class="btn social-btn btn-rounded btn-danger mr-4"
                      @click="removeImage('certificate_fitness')"
                    >
                      <i class="mdi mdi-close"></i>
                    </button>
                  </div>
                </b-form-group>

                <b-form-group
                  label="Certificate Permit"
                  label-for="certificate-permit-input"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="7"
                >
                  <div v-if="!form.certificate_permit">
                    <b-form-file
                      id="certificate-ermit-input"
                      accept="image/jpeg, image/png, image/gif"
                      placeholder="Choose a certificate permit or drop it here..."
                      @change="onFileChange($event, 'certificate_permit')"
                    ></b-form-file>
                  </div>
                  <div v-else>
                    <img
                      class="img-fluid"
                      :src="form.certificate_permit"
                      width="350"
                      height="250"
                    />
                    <button
                      class="btn social-btn btn-rounded btn-danger mr-4"
                      @click="removeImage('certificate_permit')"
                    >
                      <i class="mdi mdi-close"></i>
                    </button>
                  </div>
                </b-form-group>

                <b-form-group class="col-md-6 offset-md-5">
                  <b-button
                    type="submit"
                    class="btn btn-lg btn-success text-center"
                  >
                    <span
                      class="pl-2 spinner-border spinner-border-sm"
                      v-show="submitted"
                    >
                    </span
                    >Submit</b-button
                  >
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb";
import {
  busService,
  buslayoutService,
  bustypeService,
} from "../../../services";
import { validationMixin } from "vuelidate";
import {
  required,
  alphaNum,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";

export default {
  name: "busedit",
  mixins: [validationMixin],
  data() {
    return {
      breadcrumbs: {
        title: "Edit bus",
        b1: "Manage buses",
        b2: "bus",
        b3: "Index",
        link: true,
        name: "buses",
      },
      options: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false", default: true },
      ],
      form: {
        adminId: "",
        name: "",
        reg_no: null,
        brand: "",
        model_no: "",
        chassis_no: "",
        bustypeId: null,
        buslayoutId: null,
        picture: "",
        amenities: [],
        certificate_registration: "",
        certificate_pollution: "",
        certificate_insurance: "",
        certificate_fitness: "",
        certificate_permit: "",
        status: "",
      },
      submitted: false,
      loading: false,
      error: "",
      success: "",
      bustypes: [],
      buslayouts: [],
      amenitieslists: [
        {
          text: "AC",
          value: "ac",
        },
        {
          value: "snacks-and-drinks",
          text: "Snacks and drinks",
        },
        {
          text: "WC",
          value: "wc",
        },
        {
          text: "WIFI",
          value: "wifi",
        },
        {
          text: "GPS Tracking",
          value: "gps",
        },
        {
          value: "usb",
          text: "USB charging portal",
        },
        {
          value: "tv",
          text: "TV Screen",
        },
      ],
    };
  },
  validations: {
    form: {
      name: {
        required,
        alphaNumSpace: helpers.regex("alphaNumSpace", /^[a-z0-9_ ]*$/i),
        async uniqueName(value) {
          if (value === "") return true;
          if (value != "") {
            const { status } = await busService.isBusExists({ name: value });
            if (status) {
              return status;
            } else {
              return status;
            }
          }
        },
      },
      reg_no: {
        required,
        alphaNumSpace: helpers.regex("alphaNumSpace", /^[a-z0-9_ ]*$/i),
        minLength: minLength(4),
        maxLength: maxLength(10),
        async uniqueRegNo(value) {
          if (value === "") return true;
          if (value != "") {
            const { status } = await busService.isBusExists({ reg_no: value });
            if (status) {
              return status;
            } else {
              return status;
            }
          }
        },
      },
      brand: { required, alphaNum },
      model_no: {
        required,
        alphaNum,
        async uniqueModelNo(value) {
          if (value === "") return true;

          const { status } = await busService.isBusExists({ model_no: value });
          if (status) {
            return status;
          } else {
            return status;
          }
        },
      },
      chassis_no: {
        required,
        alphaNum,
        async uniqueChassisNo(value) {
          if (value === "") return true;

          const { status } = await busService.isBusExists({
            chassis_no: value,
          });
          if (status) {
            return status;
          } else {
            return status;
          }
        },
      },
      amenities: { required },
      bustypeId: { required },
      buslayoutId: { required },
      status: { required },
    },
  },
  components: {
    Breadcrumb,
  },
  methods: {
    async loadBustypeItems() {
      const response = await bustypeService.load();
      if (response.status) {
        this.bustypes = response.data;
      }
    },
    async loadBuslayoutItems() {
      const response = await buslayoutService.load();
      if (response.status) {
        this.buslayouts = response.data;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onFileChange(e, fileTitle) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(files);
      if (!files.length) return;
      this.createImage(files[0], fileTitle);
    },
    createImage(file, fileTitle) {
      // var picture = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.form[fileTitle] = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (titlename) {
      this.form[titlename] = "";
    },
    async getbus() {
      try {
        const response = await busService.find(this.$route.params.id);
        if (response.status) {
          this.form = response.data;
        }
      } catch (e) {
        console.log("params", e);
        this.$toast.open({
          message: e,
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      }
    },
    async updateBus() {
      try {
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.form.$anyError) {
          console.log("this.$v.$invalid", this.$v.$invalid);

          this.submitted = false;
          window.scrollTo({
            top: 4,
            left: 0,
            behavior: "smooth",
          });
          return;
        }

        const response = await busService.update(
          this.$route.params.id,
          this.form
        );
        if (response.status) {
          this.submitted = false;
          this.$toast.open({
            message: response.message,
            type: "success",
            position: "top-right",
            duration: 2000,
            // all of other options may go here
          });
          setTimeout(
            () =>
              this.$router.push({
                path: `/buses`,
              }),
            2000
          );
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      }
    },
  },
  mounted() {
    this.getbus();
    this.loadBustypeItems();
    this.loadBuslayoutItems();
  },
};
</script>

<style lang="scss" scoped></style>
